import React, { Fragment } from 'react';
import Link from 'next/link';
import {
    WbHeader,
    WbLogo,
} from '@digital-engineering-experiences/wb-webcomponents';
import Search from '../Search';

import s from './Header.module.css';
import { useRouter } from 'next/router';
import { HeaderProps } from '@/types/components/header';

const Header = ({
    navigation,
    logo,
    stickyHeader,
    siteName,
    translationsCurrentPage,
    availableLanguages,
    search,
}: HeaderProps) => {
    // If siteName is empty, set a space to avoid the header from collapsing
    const siteNameHeader = siteName === '' ? ' ' : siteName;

    const router = useRouter();

    const isActive = (languageCode: string) => {
        return router.locale === languageCode;
    };

    return (
        <WbHeader
            sticky={stickyHeader}
            logo={
                <Link href={'/'}>
                    {logo ? (
                        <img
                            src={logo.url}
                            alt="logo with link to homepage"></img>
                    ) : (
                        <WbLogo />
                    )}
                </Link>
            }
            apptitle={siteNameHeader}>
            <>
                {navigation && (
                    <nav>
                        <ul>
                            {navigation.map((item, index) => {
                                return (
                                    <li key={'nav' + index}>
                                        <Link href={`${item.url}`}>
                                            {item.title}
                                        </Link>
                                        {item.submenu.length > 0 && (
                                            <ul>
                                                {item.submenu.map(
                                                    (subitem, subindex) => {
                                                        return (
                                                            <li
                                                                key={
                                                                    'sub' +
                                                                    subindex
                                                                }>
                                                                <Link
                                                                    href={`${item.url}${subitem.slug}`}>
                                                                    {
                                                                        subitem.title
                                                                    }
                                                                </Link>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                )}

                {search && <Search {...search} />}

                {translationsCurrentPage.length > 1 && (
                    <nav className={s.language}>
                        <ul>
                            <li>
                                {translationsCurrentPage.map(
                                    (translation, index) => {
                                        const isLastItem =
                                            index ===
                                            availableLanguages.length - 1;
                                        return (
                                            <Fragment
                                                key={translation.languageCode}>
                                                <Link
                                                    href={translation.slug}
                                                    className={`${
                                                        s.languageButton
                                                    } ${
                                                        isActive(
                                                            translation.languageCode
                                                        )
                                                            ? s.activeLanguage
                                                            : ''
                                                    }`}
                                                    locale={false}>
                                                    {translation.languageCode.toUpperCase()}
                                                </Link>
                                                {!isLastItem && (
                                                    <hr
                                                        className={
                                                            s.languageDivider
                                                        }
                                                    />
                                                )}
                                            </Fragment>
                                        );
                                    }
                                )}
                            </li>
                        </ul>
                    </nav>
                )}
            </>
        </WbHeader>
    );
};

export default Header;
