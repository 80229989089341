import { WbCard } from '@digital-engineering-experiences/wb-webcomponents';
import s from './CardBlock.module.css';
import { CardsBlockProps } from '@/types/components/blocks';
import { useRouter } from 'next/router';

const CardBlock = ({ data }: { data: CardsBlockProps }) => {
    const backgroundcolor = data.bgcolor?.backgroundColor || '';

    const router = useRouter();
    const getLanguage = router.locale ? router.locale : 'nl';
    const hasCardsBlockIntro =
        data.cardsblockTitle != '' || data.cardsblockDescription != '';
    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    return (
        <div className={backgroundcolor}>
            <div className={s.CardBlock}>
                <div className="wb-container">
                    {hasCardsBlockIntro && (
                        <div
                            className={
                                s.CardBlock__inner +
                                ' ' +
                                s[data?.introTextAlign]
                            }>
                            {data.cardsblockTitle && (
                                <h2>{data.cardsblockTitle}</h2>
                            )}
                            {data.cardsblockDescription && (
                                <p>{data.cardsblockDescription}</p>
                            )}
                        </div>
                    )}

                    <div className={s.CardBlock__layout}>
                        {data.cards.map((card, index) => {
                            const cardBackgroundColor =
                                card.bgcolor?.backgroundColor || '';
                            let image = {
                                src: '',
                                alt: '',
                            };
                            const checkImage = () => {
                                if (card.image?.image) {
                                    image = {
                                        src: card.image.image.img.src,
                                        alt: card.image.image.img.alt,
                                    };
                                } else if (data.defaultImage) {
                                    image = {
                                        src: data.defaultImage.img.src,
                                        alt: data.defaultImage.img.alt,
                                    };
                                } else {
                                    return image; // empty image
                                }
                            };
                            checkImage();

                            return (
                                <div
                                    key={index}
                                    className={
                                        s.CardItem + ' ' + s[data?.cardType]
                                    }>
                                    <WbCard
                                        cardType={
                                            data.cardType.includes('horizontal')
                                                ? 'horizontal'
                                                : data.cardType.includes(
                                                      'basic'
                                                  )
                                                ? 'basic'
                                                : 'default'
                                        }
                                        bgcolor={
                                            cardBackgroundColor !== ''
                                                ? ' ' + cardBackgroundColor
                                                : ''
                                        }
                                        cardtitle={card.title && card.title}
                                        description={
                                            card.description && card.description
                                        }
                                        date={
                                            card.date &&
                                            new Date(card.date).toLocaleString(
                                                getLanguage,
                                                dateOptions
                                            )
                                        }
                                        img={image && image.src}
                                        imgAlt={image && image.alt}
                                        link={card.link[0]?.value}
                                        imgHoverColor={true}></WbCard>
                                </div>
                                //imgHoverColor, date, categories, cardtitle, description, children, link
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardBlock;
